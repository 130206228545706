import * as TYPES from '../actionTypes';

export const showModal = ({ name, data = {} }) => ({
  type: TYPES.MODAL_OPEN,
  payload: { name, data },
});

export const hideModal = () => ({
  type: TYPES.MODAL_HIDE,
});
