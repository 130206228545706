import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';

import async from './middlewares/async';
import reducers from './store/reducers/reducers';


const configureStore = () => {
  const store = createStore(reducers, composeWithDevTools(
    applyMiddleware(
      thunk,
      routerMiddleware(browserHistory),
      async,
    )
  ));

  if(process.env.NODE_ENV !== 'production') {
    if(module.hot) {
      module.hot.accept('./store/reducers/reducers', () => {
        store.replaceReducer(reducers);
      });
    }
  }

  return store;
};

export default configureStore;
