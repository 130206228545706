import React from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { connect } from 'react-redux';
import { Button, CheckboxField, Layout, PasswordField } from 'components';
import { isCheckboxChecked, isEmailValid, isPasswordValid, isRequired } from 'utils/validationMethods';
import { activateUser } from 'actions/auth';
import * as TYPES from 'store/actionTypes';

import TermsAndConditions from './TermsAndConditions.pdf';

import './ActivateUser.scss';


const ActivateUser = ({ activateUser }) => {
  return (
    <Layout waitFor={[TYPES.ACTIVATE_USER]}>
      <div className="activate">
        <div className="small-container">
          <h2>Aktiver konto</h2>
          <Form
            onSubmit={activateUser}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting }) => (
              <form
                noValidate
                className="activation-form"
                onSubmit={handleSubmit}
              >
                <div className="field">
                  <Field
                    fullWidth
                    component={TextField}
                    label="Fornavn"
                    name="firstName"
                    type="text"
                    validate={isRequired}
                  />
                </div>
                <div className="field">
                  <Field
                    fullWidth
                    component={TextField}
                    label="Etternavn"
                    name="lastName"
                    type="text"
                    validate={isRequired}
                  />
                </div>
                <div className="field">
                  <Field
                    fullWidth
                    component={TextField}
                    label="E-post adresse"
                    name="email"
                    type="email"
                    validate={isEmailValid}
                  />
                </div>
                <div className="field">
                  <Field
                    component={PasswordField}
                    label="Passord"
                    name="password"
                    type="password"
                    validate={isPasswordValid}
                  />
                </div>
                <div className="field checkbox-field">
                  <Field
                    component={CheckboxField}
                    label={<p>Jeg er enig med  <a href={TermsAndConditions} rel="noreferrer" target="_blank">vilkårene og personvernreglene</a>.</p>}
                    name="conditions"
                    type="checkbox"
                    validate={isCheckboxChecked}
                  />
                </div>
                <div className="btns-container">
                  <Button
                    disabled={submitting}
                    name="Fortsett"
                    type="submit"
                    size="large"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = {
  activateUser,
};

export default connect(null, mapDispatchToProps)(ActivateUser);
