import * as TYPE from '../actionTypes';

const INITIAL_STATE = {
  name: null,
  data: {},
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPE.MODAL_OPEN:
      return {
        ...state,
        ...action.payload,
      };

    case TYPE.MODAL_HIDE:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
