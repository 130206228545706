import { combineReducers } from 'redux';

import loaderReducer from './loaderReducer';
import loginReducer from './loginReducer';
import profileReducer from './profileReducer';
import modalReducer from './modalReducer';
import organizationReducer from './organizationReducer';
import videosReducer from './videosReducer';
import quizzesReducer from './quizzesReducer';

export default combineReducers({
  isLoggedIn: loginReducer,
  loading: loaderReducer,
  modalData: modalReducer,
  profile: profileReducer,
  organization: organizationReducer,
  videosData: videosReducer,
  quizzes: quizzesReducer,
});
