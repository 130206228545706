import { APP_PREFIX } from '../consts/app';

export const getStorageItem = key => {
  try {
    return localStorage.getItem(`${APP_PREFIX}_${key}`);
  } catch (e) {
    console.warn(e.name);
  }
};

export const hasStorageKey = key => !!getStorageItem(key);

export const setStorageItem = (key, value) => {
  try {
    localStorage.setItem(`${APP_PREFIX}_${key}`, value);
  } catch (e) {
    if(e.code === 22 || e.code === 1014) {
      console.warn('Local storage quota exceeded');
    }
    console.warn(e.name);
  }
};

export const removeStorageItem = key => {
  try {
    localStorage.removeItem(`${APP_PREFIX}_${key}`);
  } catch(e) {
    console.warn(e.name);
  }
};
