import { QUIZZES_MAX_SCORES, QUIZ_MIN_CERT_SCORE_PERCENTAGE } from 'consts/quizzes';
import { api, BASE_URL } from 'utils/api';

import * as TYPES from '../actionTypes';


export const getQuizzes = () => ({
  async: async(dispatch, getState) => {
    let { data: { items: quizzes } } = await api.get(`${BASE_URL}typeform?query=forms?page_size=200`);
    const quizzesResponsesPromises = quizzes.map(quiz => api.get(`${BASE_URL}typeform?query=forms/${quiz.id}/responses?query=${getState().profile.email}&page_size=1000`));
    const quizzesResponsesData = await Promise.all(quizzesResponsesPromises);
    const quizzesNames = Object.keys(QUIZZES_MAX_SCORES);

    quizzes = quizzes.reduce((acc, quiz, idx) => {
      if (quizzesNames.includes(quiz.title)) {
        const updatedQuiz = {
          ...quiz,
          answers: quizzesResponsesData[idx].data.items,
        };
        ({ isPassed: updatedQuiz.isPassed, percentageScore: updatedQuiz.percentageScore } =
          getUserQuizResult(updatedQuiz, getState().profile.email));

        acc.push(updatedQuiz);
      }

      return acc;
    }, []);

    dispatch({ type: TYPES.GET_QUIZZES, payload: quizzes });
  },
  defaultErrorMessage: 'Cannot get quiz data',
  type: TYPES.GET_QUIZZES,
});

const getQuizPercentageScore = (userScore, quizTitle) =>{
  return Math.round(((userScore / QUIZZES_MAX_SCORES[quizTitle]) * 100));
};

const getUserQuizResult = (quiz, userEmail) => {
  const userResponses = quiz.answers.filter(answer => answer.hidden && (answer.hidden.email === userEmail));
  const [ userLastResponse ] = userResponses;
  const userScore = userLastResponse && userLastResponse.calculated ?
    getQuizPercentageScore(userLastResponse.calculated.score, quiz.title) :
    0;

  return {
    isPassed: userScore >= QUIZ_MIN_CERT_SCORE_PERCENTAGE,
    percentageScore: userScore,
  };
};
