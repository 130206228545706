import React from 'react';

import { Button } from '../../../components';

import './OnlineCourses.scss';

const OnlineCourses = () => {
  return (
    <div className="online-courses-container">
      <div className="small-container online-courses-section">
        <div className="courses-description">
          <h3>Our online courses</h3>
          <p>Get access to our videos which are a great start in your training preparation, refresh your knowledge. Get access to our videos which are a great start in your training preparation, refresh your knowledge.</p>
        </div>
        <ul>
          <li>Constant videos updates</li>
          <li>Constant videos updates</li>
          <li>Constant videos updates</li>
          <li>Constant videos updates</li>
          <li>Constant videos updates</li>
        </ul>
        <div className="courses-pricing">
          <h5>150kr/month</h5>
          <Button name="start the course" size="large" />
        </div>
      </div>
    </div>
  );
};

export default OnlineCourses;
