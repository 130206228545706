import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { editProfile } from 'actions/profile';
import { Layout } from 'components';
import { logoutUser } from 'actions/auth';
import * as TYPES from 'store/actionTypes';

import EditModeUserBasicInfo from './EditModeUserBasicInfo';

import './Profile.scss';


const Profile = ({ editProfile, logoutUser, profile }) => {
  const [editModeSection, setEditModeSection] = useState(null);

  const { email, firstName, lastName } = profile;
  const isBasicInfoInEditMode = editModeSection === 'basic';

  return (
    <Layout waitFor={[TYPES.GET_PROFILE]}>
      <div className="small-container">
        <div className="profile-section">
          <h3>Din profil</h3>
          <h4>Forsikre deg om at alle detaljer er riktige. </h4>
          <div className="profile-info">
            <h5>Profilinformasjon:</h5>
            {
              !isBasicInfoInEditMode && (
                <span onClick={() => setEditModeSection('basic')}>Rediger</span>
              )
            }
          </div>
          {
            isBasicInfoInEditMode ?
              <EditModeUserBasicInfo
                handleCancelEditMode={() => setEditModeSection(null)}
                saveEditedUserData={
                  values => {
                    editProfile(values);
                    setEditModeSection(null);
                  }
                }
                profile={profile}
              /> : (
                <Fragment>
                  <p>{firstName} {lastName}</p>
                  <p className="email">{email}</p>
                  <span className="logout" onClick={logoutUser}>logg ut</span>
                </Fragment>
              )
          }
        </div>
        <div className="app-version">
          versjon 2.0
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = ({ profile }) => {
  return {
    profile,
  };
};

const mapDispatchToProps = {
  editProfile,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
