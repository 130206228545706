import React, { Fragment, useState, useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Button } from 'components';
import { getQuizzes } from 'actions/quizzes';
import { setLoader, hideLoader } from 'actions/loader';
import { setVideoVisibility, setCurrentVideoTitle, setCurrentVideoId } from 'actions/videos';
import { hideModal } from 'actions/modal';

import './QuizResult.scss';
import Loader from '../Loader';

const QuizResult = ({ isQuizPassed, hideModal, setVideoVisibility, getQuizzes, setLoader, hideLoader }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setLoader('QUIZ_RESULT');
    setIsSubmitting(true);

    setTimeout(async() => {
      hideLoader('QUIZ_RESULT');
      await getQuizzes();
      setIsSubmitting(false);
    }, 10000);
  }, []);

  const handleReplayVideo = () => {
    hideModal();
    setVideoVisibility(true);
  };

  const renderFailContent = () => {
    return (
      <Fragment>
        <span className="result-info">
          Hei! Du hadde mindre enn 50% riktig på denne quizen, det anbefales at du prøver igjen, You can do it!
        </span>
        <div className="buttons buttons-container">
          <Button
            name="Lukk"
            size="medium"
            handleClick={hideModal}
          />
          <Button
            name="Se videoen igjen"
            theme="dark"
            size="medium"
            handleClick={handleReplayVideo}
          />
        </div>
      </Fragment>
    );
  };

  const renderSuccessContent = () => {
    return (
      <Fragment>
        <span className="result-info">
          Du har bestått testen!
        </span>
        <div className="buttons buttons-container">
          <Button
            name="Se andre videoer"
            theme="dark"
            size="medium"
            handleClick={hideModal}
          />
        </div>
      </Fragment>
    );
  };

  const renderContent = () => {
    if (isQuizPassed) {
      return renderSuccessContent();
    }

    return renderFailContent();
  };

  const resultIconClassNames = cn({
    'o-circle': true,
    'c-container__circle': true,
    'o-circle__sign--success': isQuizPassed,
    'o-circle__sign--failure': !isQuizPassed,
  });

  const containerClassNames = cn('container', {
    'container-success': isQuizPassed && !isSubmitting,
  });

  return (
    <div className={containerClassNames}>
      {isSubmitting ?
        <Fragment>
          <Loader waitFor={[ 'QUIZ_RESULT' ]} />
          <h3 className="result-header">
            Calculating quiz score...
          </h3>
        </Fragment> :
        <Fragment>
          <h3 className="result-header">
            {isQuizPassed ? 'Gratulerer!' : 'Å NEI!'}
          </h3>
          <section className="c-container">
            <div className={resultIconClassNames}>
              <div className="o-circle__sign" />
            </div>
          </section>
          <div className="content-container">
            {renderContent()}
          </div>
        </Fragment>
      }
    </div>
  );
};

const mapStateToProps = ({ quizzes, modalData: { data } }) => {
  const submittedQuiz = data && data.quizId ? quizzes.find(quiz => quiz.id === data.quizId) : null;
  const { isPassed: isQuizPassed } = submittedQuiz;

  return {
    isQuizPassed,
    modalData: data,
  };
};

const mapDispatchToProps = {
  getQuizzes,
  setVideoVisibility,
  setCurrentVideoTitle,
  setCurrentVideoId,
  hideModal,
  setLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizResult);
