import React from 'react';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import * as typeformEmbed from '@typeform/embed';
import { Button } from 'components';
import { connect } from 'react-redux';
import { getQuizzes } from 'actions/quizzes';
import { showModal, hideModal } from 'actions/modal';
import { QUIZ_ON_QUIZ_SUBMIT } from 'consts/modals';

import './PreQuiz.scss';

const PreQuiz = ({ showModal, hideModal, modalData }) => {
  const checkQuizPassed = () => {
    setTimeout(async() => {
      showModal({
        data: modalData,
        name: QUIZ_ON_QUIZ_SUBMIT,
      });
    }, 3000);
  };

  const handleShowQuizModal = () => {
    hideModal();
    const popup = typeformEmbed.makePopup(
      modalData.quizUrl,
      {
        mode: 'popup',
        onSubmit: checkQuizPassed,
        autoClose: 3,
      }
    );

    popup.open();
  };

  return (
    <div className="container">
      <h3>
        Tid for Quiz!
      </h3>
      <ContactSupportIcon className="quiz-icon" />
      <div className="buttons buttons-container">
        <Button
          name="Lukk"
          size="medium"
          handleClick={hideModal}
        />
        <Button
          name="Vis quiz"
          theme="dark"
          size="medium"
          handleClick={handleShowQuizModal}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ modalData: { data } }) => ({
  modalData: data,
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  getQuizzes,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreQuiz);
