import { logoutUser } from '../store/actions/auth';

export const handleError = (status, dispatch) => {
  if(status === 403) {
    return dispatch(logoutUser());
  }
};

const errorActions = { handleError };
export default errorActions;
