import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { EMAIL_SUBJECT, OWNER_EMAIL } from 'consts/emails';
import { ADDITIONAL_RESOURCES_URL } from 'consts/urls';
import * as TYPES from 'store/actionTypes';

import Button from '../Button';

import './Navigation.scss';

const Navigation = ({ getAndOpenCertificate, isAbleToDownloadCert, isLoggedIn }) => {
  const [ mobileNavVisibility, setMobileNavVisibility ] = useState(false);

  return (
    <Fragment>
      {!isLoggedIn ? (
        <div className="nav-desktop-logged-out">
          <a href={`mailto:${OWNER_EMAIL}?Subject=${EMAIL_SUBJECT}`}>KONTAKT</a>
        </div>
      ) : (
        <Fragment>
          <nav className="nav-desktop-logged-in">
            <a href={ADDITIONAL_RESOURCES_URL} target="_blank">LAST NED VEDLEGG</a>
            <Link to="/profile">
              <h3 className="nav-item">DIN PROFIL</h3>
            </Link>
            <a href={`mailto:${OWNER_EMAIL}?Subject=${EMAIL_SUBJECT}`}>KONTAKT</a>
            {isAbleToDownloadCert &&
              <Button
                handleClick={getAndOpenCertificate}
                name="Last ned kursbeviset"
                size="medium"
                waitFor={[TYPES.GET_CERTIFICATE]}
              />
            }
          </nav>
          <div
            className="mobile-nav-icon"
            onClick={() => setMobileNavVisibility(!mobileNavVisibility)}
          >
            { mobileNavVisibility ? <Close /> : 'MENU' }
          </div>
          {mobileNavVisibility && (
            <nav className="mobile-nav-list">
              <Link to={isLoggedIn ? '/videos' : '/'}><li>Hjem</li></Link>
              <a href={ADDITIONAL_RESOURCES_URL} target="_blank"><li>Last ned vedlegg</li></a>
              <Link to="/profile"><li>Din profil</li></Link>
              <a href={`mailto:${OWNER_EMAIL}?Subject=${EMAIL_SUBJECT}`}><li>Contact</li></a>
              {isAbleToDownloadCert && <li onClick={getAndOpenCertificate}>Download certificate</li>}
            </nav>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Navigation;
