import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setUserLoginStatus } from 'actions/auth';
import { getAndOpenCertificate } from 'actions/certificate';

import Navigation from '../Navigation';

import './Header.scss';

const Header = ({ getAndOpenCertificate, isAbleToDownloadCert, isLoggedIn, setUserLoginStatus }) => {
  useEffect(() => {
    setUserLoginStatus();
  }, []);

  return (
    <div className="header">
      <div className="main-container header-container">
        <Link to={isLoggedIn ? '/videos' : '/'}>
          <div className="logo-container">
            <img className="header-logo" src="../images/Penzo-logo-horizontal-beige.svg" alt="logo" />
          </div>
        </Link>
        <Navigation
          getAndOpenCertificate={getAndOpenCertificate}
          isAbleToDownloadCert={isAbleToDownloadCert}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ isLoggedIn }) => {
  return {
    isLoggedIn,
  };
};

const mapDispatchToProps = {
  getAndOpenCertificate,
  setUserLoginStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
