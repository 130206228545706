import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Button, Layout, PasswordField } from 'components';
import { logoutUser, resetPassword } from 'actions/auth';
import { isPasswordValid } from 'utils/validationMethods';
import * as TYPES from 'store/actionTypes';

import './ResetPassword.scss';

const ResetPassword = ({ logoutUser, resetPassword }) => {
  useEffect(() => {
    logoutUser(false);
  }, []);

  return (
    <Layout waitFor={[TYPES.RESET_PASSWORD]}>
      <div className="small-container">
        <div className="new-password">
          <h2>NYTT PASSORD</h2>
          <h3>Velg ditt nye passord</h3>
          <Form
            onSubmit={values => resetPassword(values.password, 'resetPasswordConfirm')}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting }) => (
              <form
                noValidate
                className="new-password-form"
                onSubmit={handleSubmit}
              >
                <Field
                  component={PasswordField}
                  label="Passord"
                  name="password"
                  type="password"
                  validate={isPasswordValid}
                />
                <div className="btns-container">
                  <Button
                    disabled={submitting}
                    name="endre"
                    size="large"
                    type="submit"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = {
  logoutUser,
  resetPassword,
};

export default connect(null, mapDispatchToProps)(ResetPassword);
