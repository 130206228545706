import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { api } from '../utils/api';

export default (WrappedComponent, queryParamKey) => {
  return props => {
    const param = useMemo(() => api.getQueryParams(queryParamKey), [queryParamKey]);
    const isParamAvailable = !!param;

    return isParamAvailable ? <WrappedComponent {...props} /> : <Redirect to="/404" />;
  };
};
