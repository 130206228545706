import React from 'react';

import './Footer.scss';

const Footer = () => {
  return(
    <div className="footer-container">
      <div className="small-container footer">
        <div className="footer-info-list">
          <span>TATIANA PENZO</span>
          <span>•</span>
          <a href="https://goo.gl/maps/LJvzgzd4GHn" target="_blank" rel="noopener">TORGGATA 13, OSLO</a>
          <span>•</span>
          <a href="tel:+47 932 23 321">+47 932 23 321</a>
          <span>•</span>
          <a href="mailto:tatiana@penzo.no?Subject=Penzo.no">TATIANA@PENZO.NO</a>
          <span>•</span>
          <a href="https://w2.brreg.no/enhet/sok/detalj.jsp;jsessionid=rds8kCwKpDvmZoGBR5RrOWI9NdSfHufiXy7zJWLhs7gmEybet-Zo!189513349?orgnr=981658736" target="_blank" rel="noopener">ORG. NR. 981658736</a>
        </div>
        <span>&copy; {`2000-${new Date().getFullYear()} Penzo`}</span>
      </div>
    </div>
  );
};

export default Footer;
