import React, { useEffect } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { Button, Loader } from 'components';
import { getOrganizationData } from 'actions/organization';
import { EMAIL_SUBJECT, OWNER_EMAIL } from 'consts/emails';
import * as TYPES from 'store/actionTypes';

import './OrganizationPanel.scss';
import 'react-table/react-table.css';

const OrganizationPanel = ({
  getOrganizationData,
  organization: { name, accountsLeft, users = [], userLimit },
}) => {
  const columns = [{
    Header: '',
    accessor: 'no',
    minWidth: 20,
    className: 'person-id',
  }, {
    Header: 'Name',
    accessor: 'firstName',
    minWidth: 100,
  }, {
    Header: 'Email',
    accessor: 'email',
    minWidth: 300,
  }, {
    id: 'Activated',
    Header: 'Activated',
    accessor: values => {
      const formatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
      const date = new Date(values.createdAt);
      return date.toLocaleDateString('no-NO', formatOptions);
    },
  }];

  useEffect(() => {
    getOrganizationData();
  }, []);

  return (
    <div className="main-container">
      <Loader waitFor={[TYPES.GET_ORGANIZATION_DATA]} />
      <img className="header-logo" src="../images/Penzo-logo-horizontal-beige.svg" alt="logo" />
      <div className="small-container">
        <div className="org-panel-container">
          <h1>Hi, {name}!</h1>
          <div className="sub-header-container">
            <p>You have <span>{accountsLeft}</span> accounts left.</p>
            <a href={`mailto:${OWNER_EMAIL}?Subject=${EMAIL_SUBJECT}`}>
              <Button
                name="Request more accounts"
                size="large"
              />
            </a>
          </div>
          <h2>Accounts:</h2>
          <div className="table-container">
            <ReactTable
              data={users}
              columns={columns}
              pageSize={Number(userLimit)}
              showPagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ loading, organization }) => {
  return {
    loading,
    organization,
  };
};

const mapDispatchToProps = {
  getOrganizationData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPanel);
