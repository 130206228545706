import React from 'react';
import { Field, Form } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Button } from 'components';
import { isEmailValid } from 'utils/validationMethods';

import './ForgottenPassword.scss';

const ForgottenPassword = ({ onCancel, onAgree }) => {
  return (
    <div className="forgotten-pass-container">
      <Form
        onSubmit={values => onAgree(values.email)}
        render={({ handleSubmit, submitting }) => (
          <form
            noValidate
            className="login-form"
            onSubmit={handleSubmit}
          >
            <div className="password-field field">
              <Field
                fullWidth
                component={TextField}
                label="E-post adresse"
                name="email"
                type="email"
                validate={isEmailValid}
              />
            </div>
            <div className="btns-container">
              <span onClick={onCancel}>Avbryt</span>
              <Button
                disabled={submitting}
                name="Send tilbakestillingslenke"
                size="medium"
                type="submit"
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default ForgottenPassword;
