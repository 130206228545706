import * as TYPES from '../actionTypes';
import { objToCamelCase } from '../../utils/formatters';

export default (state = {}, action) => {
  switch(action.type) {
    case TYPES.GET_PROFILE:
      return objToCamelCase(action.payload);

    default:
      break;
  }

  return state;
};
