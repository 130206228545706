const loaderReducer = (state = [], action ) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|DONE)/.exec(type);

  if(!matches) return state;

  const [, requestName, requestState ] = matches;

  return (requestState === 'REQUEST')
    ? [ requestName, ...state ]
    : state.filter(item => item !== requestName);
};

export default loaderReducer;
