import * as TYPES from '../actionTypes';

const quizzesInitialState = [];

export default (state = quizzesInitialState, action) => {
  switch (action.type) {
    case TYPES.GET_QUIZZES: {
      return [ ...action.payload ];
    }

    default: {
      return state;
    }
  }
};
