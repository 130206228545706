import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import './CheckboxField.scss';

const CheckboxField = ({ input, label, meta }) => {
  return (
    <div className="checkbox-field">
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            checked={input.value}
            color="default"
            name={name}
            onChange={input.onChange}
          />
        }
      />
      {meta.error && meta.touched && <span className="checkbox-error">{meta.error}</span>}
    </div>
  );
};

export default CheckboxField;
