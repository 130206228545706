import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Button, Layout } from 'components';
import {
  getVideos, setCourseCompletion, setCurrentVideoCompletionStatus,
  setVideoVisibility, setCurrentVideoTitle, setCurrentVideoId,
} from 'actions/videos';
import { showModal, hideModal } from 'actions/modal';
import { getQuizzes } from 'actions/quizzes';
import { getStorageItem, hasStorageKey } from 'utils/localStorage';
import * as TYPES from 'store/actionTypes';
import * as MODAL_CONSTS from 'consts/modals';

import { VideoItem } from '..';

import './Videos.scss';

const STORAGE_VIDEOS_DATA_KEY = 'VIDEOS_DATA';


const Videos = ({
  getQuizzes, getVideos, profile, isCurrentVideoCompleted, videos = [], quizzes = [],
  setCourseCompletion, setCurrentVideoCompletionStatus, showModal, currentVideoId, setCurrentVideoId,
  currentVideoTitle, setCurrentVideoTitle, videoVisibility, setVideoVisibility,
}) => {
  const [ firstMovie = {} ] = videos;
  const getVideoQuiz = videoTitle => quizzes.find(quiz => quiz.title === videoTitle);
  const currentVideoQuiz = getVideoQuiz(currentVideoTitle);

  useEffect(() => {
    !videos.length && getVideos();
    !quizzes.length && getQuizzes();
  }, []);

  const [ hasCourseStarted, setHasCourseStarted ] = useState(false);

  useEffect(() => {
    const videosStorageData = getStorageData();

    const [ storageVideoPlayedValue = 0 ] = Object.values(videosStorageData);
    const isStorageEmpty = Object.values(videosStorageData).length === 0;

    const hasCourseStarted = storageVideoPlayedValue !== 0 && !isStorageEmpty;

    setHasCourseStarted(hasCourseStarted);
  }, [videoVisibility]);

  const getStorageData = () => {
    return hasStorageKey(STORAGE_VIDEOS_DATA_KEY)
      ? JSON.parse(getStorageItem(STORAGE_VIDEOS_DATA_KEY))
      : {};
  };

  const handleVideoBoxClick = videoItem => {
    setVideoVisibility(true);
    setCurrentVideoId(videoItem.vimeoId);
    setCurrentVideoTitle(videoItem.title);
  };

  const handleStartOrContinueClick = () => {
    const videosStorageData = getStorageData();

    const [ storageVideoId ] = Object.keys(videosStorageData);

    const lastVideoId = videos[videos.length - 1].vimeoId;
    const isLastVideoFinished = isCurrentVideoCompleted && (storageVideoId === lastVideoId);

    const currentVideoIndex = videos.findIndex(item => item.vimeoId === storageVideoId);

    const studentVideoInProgress = hasCourseStarted && !isCurrentVideoCompleted;
    const firstVideoId = firstMovie.vimeoId;
    const nextVideoId = videos[currentVideoIndex + 1] && videos[currentVideoIndex + 1].vimeoId;

    const videoId = studentVideoInProgress
      ? storageVideoId
      : isLastVideoFinished
        ? firstVideoId
        : nextVideoId;

    setCurrentVideoId(videoId);
    setVideoVisibility(true);
  };

  const handleVideoEnd = useCallback(isCurrentVideoFinished => {
    const lastVideoId = videos[videos.length - 1].vimeoId;
    const isCurrentVideoLast = currentVideoId === lastVideoId;

    setCurrentVideoCompletionStatus(isCurrentVideoFinished);
    if(isCurrentVideoFinished && isCurrentVideoLast) {
      setCourseCompletion();
      !profile.courseCompleted && showModal({ name: MODAL_CONSTS.CONGRATS_ON_COURSE_END });
    }
  }, [ currentVideoTitle, currentVideoId ]);

  const getQuizIcon = videoTitle => {
    const currentVideoQuiz = getVideoQuiz(videoTitle);

    if(currentVideoQuiz) {
      if (currentVideoQuiz.isPassed) {
        return <CheckCircleOutlineRoundedIcon className="video-icon video-icon--passed" />;
      }

      return <HelpOutlineIcon className="video-icon video-icon--quiz" />;
    }

    return null;
  };

  const showQuiz = () => {
    if(currentVideoQuiz) {
      setVideoVisibility(false);
      showModal({
        name: MODAL_CONSTS.BEFORE_QUIZ,
        data: {
          quizId: currentVideoQuiz.id,
          quizUrl: `${currentVideoQuiz._links.display}?email=${profile.email}`,
        },
      });
    }
  };

  const handleCloseVideo = () => {
    setVideoVisibility(false);
  };

  const videoBox = videos && videos.map(video => {
    return (
      <div
        className="video-box"
        onClick={() => handleVideoBoxClick(video)}
        key={video.vimeoId}
      >
        {getQuizIcon(video.title)}
        <img src={video.thumbnailUrl} />
        <h3>{video.title}</h3>
        <p>{video.description}</p>
      </div>
    );
  });

  return (
    <Layout waitFor={[TYPES.GET_VIDEOS, TYPES.GET_QUIZZES]}>
      <div className="main-container">
        <div className="videos">
          <div className="videos-header-container">
            <h2>Velkommen til For Sikkerhetens Skyld Online.<br />
              Kurset tar for seg en del av den nødvendige opplæringen og øvelse i forebygging og håndtering av vold og trusler om vold.</h2>
            <Button
              name={hasCourseStarted ? 'fortsett' : 'start kurs'}
              handleClick={handleStartOrContinueClick}
            />
          </div>
          <div className="video-box-container">
            {videoBox}
          </div>
        </div>
        {
          videoVisibility &&
          <VideoItem
            vimeoId={currentVideoId}
            videoQuiz={currentVideoQuiz}
            onShowQuiz={showQuiz}
            onVideoClose={handleCloseVideo}
            onQuizClose={hideModal}
            setVideoCompletionStatus={handleVideoEnd}
          />
        }
      </div>
    </Layout>
  );
};


const mapStateToProps = ({
  profile,
  videosData: { videos, currentVideoId, currentVideoTitle, videoVisibility, isCurrentVideoCompleted },
  quizzes,
}) => {
  return {
    isCurrentVideoCompleted,
    profile,
    videos,
    currentVideoId,
    currentVideoTitle,
    videoVisibility,
    quizzes,
  };
};

const mapDispatchToProps = {
  getVideos,
  setCourseCompletion,
  setCurrentVideoCompletionStatus,
  setVideoVisibility,
  setCurrentVideoTitle,
  setCurrentVideoId,
  showModal,
  getQuizzes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
