import React, { useState } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import './PasswordField.scss';


const PasswordField = ({ input, meta, rest }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <TextField
      fullWidth
      className="password-field"
      error={showError}
      helperText={showError ? meta.error || meta.submitError : undefined}
      label="Passord"
      type={passwordVisibility ? 'text' : 'password'}
      {...input}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="password-icon-container" onClick={() => setPasswordVisibility(!passwordVisibility)}>
              {passwordVisibility ? <VisibilityOff /> : <Visibility />}
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
