import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Loader.scss';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

const CircularIndeterminate = ({
  classes: { progress },
  loading = [],
  type = 'site',
  waitFor = [],
}) => {
  const shouldShowLoader = waitFor.some(item => loading.includes(item));

  return (
    <Fragment>
      {shouldShowLoader && (
        <div className={classNames('loader-container', { [`loader-container--${type}`]: type })}>
          <CircularProgress className={progress} color="secondary" size={type === 'btn' ? 20 : 40} />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};

const connectedCircularIndeterminate = connect(mapStateToProps)(CircularIndeterminate);
export default withStyles(styles)(connectedCircularIndeterminate);
