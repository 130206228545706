export const hideLoader = actionType => {
  return {
    type: actionType + '_DONE',
  };
};

export const setLoader = actionType => {
  return {
    type: actionType + '_REQUEST',
  };
};
