import React, { Fragment, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { getProfile } from './store/actions/profile';
import history from './history';
import { withAuth, withRequiredQueryParam } from './HOC';
import { CookieBar } from './components';
import { ActivateUser, Login, Modals, OrganizationPanel, PageNotFound, Profile, ResetPassword, Videos } from './views';

import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';

const App = ({ getProfile, isLoggedIn }) => {
  useEffect(() => {
    if(isLoggedIn) getProfile();
  }, [isLoggedIn]);

  return (
    <Fragment>
      <Router history={history}>
        <Fragment>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/activation" component={withRequiredQueryParam(ActivateUser, 'token')} />
            <Route path="/reset-password" component={withRequiredQueryParam(ResetPassword, 'token')} />
            <Route path="/organization-panel" component={withRequiredQueryParam(OrganizationPanel, 'token')} />
            <Route path="/profile" component={withAuth(Profile)} />
            <Route path="/videos" component={withAuth(Videos)} />
            <Route component={PageNotFound} />
          </Switch>
          <Modals />
        </Fragment>
      </Router>
      <ToastContainer />
      <CookieBar />
    </Fragment>
  );
};

const mapStateToProps = ({ isLoggedIn }) => {
  return {
    isLoggedIn,
  };
};

const mapDispatchToProps = {
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
