import React, { useEffect, useRef, useState } from 'react';
import * as typeformEmbed from '@typeform/embed';
import { connect } from 'react-redux';
import { getQuizzes } from 'actions/quizzes';
import { showModal } from 'actions/modal';
import { QUIZ_ON_QUIZ_SUBMIT } from 'consts/modals';

import './TypeformQuiz.scss';
import Loader from '../Loader';

const TypeformQuiz = ({ quizData }) => {
  const containerRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkQuizPassed = () => {
    setIsSubmitting(true);
    setTimeout(async() => {
      await getQuizzes();

      showModal({
        data: quizData,
        name: QUIZ_ON_QUIZ_SUBMIT,
      });

      setIsSubmitting(false);
    }, 5000);
  };

  useEffect(() => {
    typeformEmbed.makeWidget(
      containerRef.current,
      quizData.quizUrl,
      {
        onSubmit: checkQuizPassed,
      }
    );
  }, []);

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div
      className="container typeform-container"
      ref={containerRef}
    />
  );
};

const mapStateToProps = ({ modalData: { data } }) => ({
  quizData: data,
});

const mapDispatchToProps = {
  getQuizzes,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TypeformQuiz);
