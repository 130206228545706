import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Footer, Header, Loader  } from '../../components';

import { FreeContent, HeroSection, OnlineCourses, TrainingInfo } from './';

import './Landing.scss';

const Landing = ({ loading }) => {
  return (
    <Fragment>
      {loading && <Loader />}
      <Header />
      <HeroSection />
      <FreeContent />
      <OnlineCourses />
      <TrainingInfo />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};

export default connect(mapStateToProps)(Landing);
