import React from 'react';

import './FreeContent.scss';

const FreeContent = () => {
  return (
    <div className="small-container">
      <div className="free-content-container">
        <h3>Online course content</h3>
        <p>Our content is focues on visual presentations with broad descriptions. Our content is focues on visual presentations with broad descriptions. </p>
        <div className="free-videos-container">
          <div>
            <iframe
              allowFullScreen
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              src="https:/www.youtube.com/embed/039kOTc2hGo"
            />
            <h5>Kollega frigjør kveling</h5>
          </div>
          <div>
            <iframe
              allowFullScreen
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              src="https:/www.youtube.com/embed/039kOTc2hGo"
            />
            <h5>Kollega frigjør kveling</h5>
          </div>
          <div>
            <iframe
              allowFullScreen
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              src="https:/www.youtube.com/embed/039kOTc2hGo"
            />
            <h5>Kollega frigjør kveling</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeContent;
