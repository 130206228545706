import React from 'react';
import classNames from 'classnames';
import { Loader } from 'components';

import './Button.scss';

const Button = ({ handleClick, name, size = 'large', waitFor = [], theme = 'light' }) => {
  const buttonCN = classNames('btn-container', { [`btn-type-${size}`]: size, [`btn-theme-${theme}`]: size });

  return (
    <div className="btn-wrapper">
      <button
        className={buttonCN}
        onClick={handleClick}
      >
        {name}
      </button>
      <Loader
        type="btn"
        waitFor={waitFor}
      />
    </div>
  );
};

export default Button;
