import React from 'react';

import { Button } from '../../../components';

import './TrainingInfo.scss';

const TrainingInfo = () => {
  return(
    <div className="training-container">
      <div className="small-container training-section">
        <div className="training-description">
          <h3>Training <br /> + courses online</h3>
          <p>Courses and lectures are adapted to companies and institutions where work can lead to the risk of being exposed to violence and threats.</p>
        </div>
        <ul>
          <li>Access to the e-learning platform</li>
          <li>Access to the e-learning platform</li>
          <li>Access to the e-learning platform</li>
          <li>Access to the e-learning platform</li>
          <li>Access to the e-learning platform</li>
        </ul>
        <div className="training-pricing">
          <h5>individual pricing</h5>
          <Button name="book the training" size="large" />
        </div>
      </div>
    </div>
  );
};

export default TrainingInfo;
