import React, { useState, useCallback } from 'react';
import { hasStorageKey, setStorageItem } from 'utils/localStorage';
import { ARE_COOKIES_ACCEPTED } from 'consts/storage';

import Button from '../Button';

import './CookieBar.scss';


const CookieBar = () => {
  const areCookiesAccepted = hasStorageKey(ARE_COOKIES_ACCEPTED);
  const [ cookieBarVisibility, setCookieBarVisibility ] = useState(!areCookiesAccepted);

  const acceptCookies = useCallback(() => {
    setStorageItem(ARE_COOKIES_ACCEPTED, true);
    setCookieBarVisibility(false);
  }, []);

  return cookieBarVisibility ? (
    <div className="cookie-bar">
      <div className="main-container cookie-bar-container">
        <p>Dette nettstedet bruker informasjonskapsler for å gi deg den beste og mest relevante erfaringen.</p>
        <p>{cookieBarVisibility}</p>
        <Button size="medium" name="OK" handleClick={acceptCookies} />
      </div>
    </div>
  ) : null;
};

export default CookieBar;
