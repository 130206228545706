import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Header, Loader } from '../';

import './Layout.scss';

const Layout = ({ children, profile, waitFor }) => (
  <Fragment>
    <Header isAbleToDownloadCert={profile.courseCompleted} />
    <Loader waitFor={waitFor} />

    <div className="layout-content">
      { children }
    </div>
  </Fragment>
);

const mapStateToProps = ({ profile }) => ({
  profile,
});

export default connect(mapStateToProps)(Layout);
