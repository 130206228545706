import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConnectedModal, TypeformQuiz, QuizResult, PreQuiz } from 'components';
import { hideModal, showModal } from 'actions/modal';
import { requestResetLink } from 'actions/auth';
import * as TYPES from 'store/actionTypes';
import * as MODAL_CONSTS from 'consts/modals';

import ForgottenPassword from '../ForgottenPassword';

import './Modals.scss';

const Modals = ({ hideModal, history, requestResetLink, showModal }) => {
  const askForResetLink = email => {
    requestResetLink(email)
      .then(() => showModal({ name: MODAL_CONSTS.RESET_LINK_CONFIRM }));
  };

  return (
    <Fragment>
      <ConnectedModal
        onAgree={email => askForResetLink(email)}
        name={MODAL_CONSTS.REQUEST_RESET_LINK}
        mainHeader="Jeg har glemt passordet mitt"
        subHeader="Skriv inn din e-postadress for å motta en lenke til å tilbakestille passordet ditt."
        waitFor={[TYPES.REQUEST_RESET]}
      >
        <ForgottenPassword />
      </ConnectedModal>

      <ConnectedModal
        isConfirmationModal
        btnText="OK"
        name={MODAL_CONSTS.RESET_LINK_CONFIRM}
        mainHeader="Lenke for tilbakestilling av passord sendt"
        subHeader="Kode for tilbakestilling av passord ble sent til din e-post adresse."
      />

      <ConnectedModal
        isConfirmationModal
        btnText="Login"
        name={MODAL_CONSTS.RESET_PASSWORD_CONFIRM}
        onAgree={() => {
          hideModal();
          history.push('/');
        }}
        mainHeader="Vellykket!"
        subHeader="Du kan nå logge inn med ditt nye passord."
      />

      <ConnectedModal
        isConfirmationModal
        btnText="OK"
        name={MODAL_CONSTS.CONGRATS_ON_COURSE_END}
        mainHeader="Gratulerer! "
        subHeader="Du har nettopp fullført kurset. For å laste ned sertifikatbruksknappen i menyen."
      />

      <ConnectedModal
        btnText="Lukk"
        className="quiz-modal quiz-modal-full"
        onClose={hideModal}
        name={MODAL_CONSTS.QUIZ_ON_COURSE_END}
      >
        <TypeformQuiz />
      </ConnectedModal>

      <ConnectedModal
        btnText="Lukk"
        className="quiz-modal"
        onClose={hideModal}
        name={MODAL_CONSTS.QUIZ_ON_QUIZ_SUBMIT}
      >
        <QuizResult />
      </ConnectedModal>

      <ConnectedModal
        btnText="Lukk"
        className="quiz-modal"
        onClose={hideModal}
        name={MODAL_CONSTS.BEFORE_QUIZ}
      >
        <PreQuiz />
      </ConnectedModal>
    </Fragment>
  );
};

const mapStateToProps = ({ loading }) => ({
  loading,
});

const mapDispatchToProps = {
  hideModal,
  requestResetLink,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Modals));
