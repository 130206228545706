import * as TYPES from '../actionTypes';
import { objToCamelCase } from '../../utils/formatters';

export default (state = {}, action) => {
  switch(action.type) {
    case TYPES.GET_ORGANIZATION_DATA:
      const formattedUsers = action.payload.users.map((item, i) => ({
        ...objToCamelCase(item),
        no: i + 1,
      }));

      const organizationData = {
        ...action.payload,
        users: formattedUsers,
      };

      return objToCamelCase(organizationData);

    default:
      break;
  }

  return state;
};
