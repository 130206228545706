import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { api } from '../utils/api';
import { logoutUser } from '../store/actions/auth';

export default ProtectedComponent => {
  const Auth = props => {
    const token = useMemo(() => api.getAuthToken(), []);

    if(token) {
      return <ProtectedComponent {...props} />;
    }

    props.logoutUser();
    return <Redirect to="/" />;
  };

  const mapDispatchToProps = {
    logoutUser,
  };

  return connect(null, mapDispatchToProps)(Auth);
};
