export const DEV_QUIZZES_MAX_SCORES = {
  'Sample Videos 5': 1,
  'Time Lapse Video Of Sky': 3,
  'Fifth Sample Video': 8,
  'Sample Videos 4': 0,
  'Sample Videos 3': 0,
  'Sample Videos 2': 0,
  'ThirdSampleVideo': 0,
};

export const PROD_QUIZZES_MAX_SCORES = {
  'Trygghet': 2,
  'Refleksiv Praksis': 2 + 3,
  'Simuleringsøvelse 2': 2 + 1 + 1 + 1,
  'Deeskalerende Kommunkasjon': 5 + 6,
  'Aggresjon': 5 + 5 + 7 + 6,
  'Kommunikasjon': 2 + 3,
  '§': 2 + 5 + 8,
  'Arbeidstilsynet': 9,

};

export const QUIZZES_MAX_SCORES = process.env.REACT_APP_QUIZZES_SOURCE === 'penzo' ?
  PROD_QUIZZES_MAX_SCORES :
  DEV_QUIZZES_MAX_SCORES;

export const QUIZZES_MIN_CERT_SCORE_PERCENTAGE = 50;
export const QUIZ_MIN_CERT_SCORE_PERCENTAGE = 50;
