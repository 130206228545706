import React from 'react';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';

import { Button } from '../../../components';

import './HeroSection.scss';


const HeroSection = () => {
  return (
    <div className="main-container">
      <div className="hero-section">
        <iframe
          allowFullScreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          src="https:/www.youtube.com/embed/039kOTc2hGo"
        />
        <div className="hero-text-container">
          <h4>For sikkerhetens skyld er et praktisk kurs for å forebygge og minimere risiko.</h4>
          <p>Kurs og foredrag tilpasses bedrifter og institusjoner der arbeid kan medføre risiko for å bli utsatt for vold og trusler.</p>
          <div className="start-container">
            <DownArrow />
            <Button name="Click to start your course" size="large" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
