export const ACTIVATE_USER = 'ACTIVATE_USER';

export const REGISTER_USER = 'REGISTER_SUCCESS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_PROFILE = 'GET_PROFILE';

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_HIDE = 'MODAL_HIDE';

export const GET_ORGANIZATION_DATA = 'GET_ORGANIZATION_DATA';

export const GET_VIDEOS = 'GET_VIDEOS';
export const SET_COURSE_COMPLETION = 'SET_COURSE_COMPLETION';
export const CURRENT_VIDEO_COMPLETION_STATUS = 'CURRENT_VIDEO_COMPLETION_STATUS';
export const SET_CURRENT_VIDEO_ID = 'SET_CURRENT_VIDEO_ID';
export const SET_CURRENT_VIDEO_TITLE = 'SET_CURRENT_VIDEO_TITLE';
export const SET_VIDEO_VISIBILITY = 'SET_VIDEO_VISIBILITY';

export const GET_QUIZZES = 'GET_QUIZZES';

export const REQUEST_RESET = 'REQUEST_RESET';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const GET_CERTIFICATE = 'GET_CERTIFICATE';
