import * as TYPES from '../actionTypes';

export default (state = false, action) => {
  switch(action.type) {
    case TYPES.LOGIN_USER:
      return true;

    case TYPES.LOGOUT_USER:
      return false;

    default:
      break;
  }

  return state;
};
