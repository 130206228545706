import * as TYPES from '../actionTypes';

import { getProfile } from './profile';
import { api, BASE_URL } from './../../utils/api';

export const getVideos = () => ({
  async: async dispatch => {
    const { data } = await api.get(`${BASE_URL}videos`);
    dispatch({ type: TYPES.GET_VIDEOS, payload: data });
  },
  defaultErrorMessage: 'Cannot get videos data',
  type: TYPES.GET_VIDEOS,
});

export const setCourseCompletion = () => ({
  async: async dispatch => {
    await api.post(`${BASE_URL}course_completion`);
    dispatch(getProfile());
  },
  defaultErrorMessage: 'Cannot set course to be completed',
  type: TYPES.SET_COURSE_COMPLETION,
});

export const setCurrentVideoCompletionStatus = isCurrentVideoFinished => ({
  type: TYPES.CURRENT_VIDEO_COMPLETION_STATUS,
  payload: isCurrentVideoFinished,
});

export const setVideoVisibility = isVideoVisible => ({
  type: TYPES.SET_VIDEO_VISIBILITY,
  payload: isVideoVisible,
});

export const setCurrentVideoTitle = videoTitle => ({
  type: TYPES.SET_CURRENT_VIDEO_TITLE,
  payload: videoTitle,
});

export const setCurrentVideoId = videoId => ({
  type: TYPES.SET_CURRENT_VIDEO_ID,
  payload: videoId,
});
