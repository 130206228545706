import { toast } from 'react-toastify';

import { hideLoader, setLoader } from '../store/actions/loader';
import { api } from '../utils/api';
import errorActions from '../utils/errorActions';


const asyncMiddleware = ({ dispatch, getState }) => next => async(action = {}) => {
  const { async, defaultErrorMessage, skipDefaultErrorActions = false, type } = action;

  if(async) {
    dispatch(setLoader(type));

    try {
      return await async(dispatch, getState);
    } catch (error) {
      if(error.response) {
        const { status } = error.response;

        const message = api.getRequestErrorMessage(error, defaultErrorMessage);
        toast.error(message);

        !skipDefaultErrorActions && errorActions.handleError(status, dispatch);
      }
    } finally {
      dispatch(hideLoader(type));
    }
  } else {
    const actionCaller = action.sync || action;

    return typeof actionCaller === 'function'
      ? actionCaller(dispatch, getState)
      : next(action);
  }
};

export default asyncMiddleware;
