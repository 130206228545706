import * as TYPES from '../actionTypes';

import { api, BASE_URL } from './../../utils/api';

export const getOrganizationData = () => ({
  async: async dispatch => {
    const token = api.getQueryParams('token');
    const { data } = await api.get(`${BASE_URL}organization`, { token });
    dispatch({ type: TYPES.GET_ORGANIZATION_DATA, payload: data });
  },
  defaultErrorMessage: 'Cannot get accounts data',
  type: TYPES.GET_ORGANIZATION_DATA,
});

