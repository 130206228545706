import axios from 'axios';
import capitalize from 'lodash.capitalize';
import isPlainObject from 'lodash.isplainobject';

import { Enum } from '../utils/Enum';

import { getStorageItem, removeStorageItem, setStorageItem } from './localStorage';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL || '/';

const STORAGE_AUTH_TOKEN_KEY = 'auth-token';

class API {
  constructor() {
    this.authToken = null;
    this.getAuthToken();
  }

  isLoggedIn = () => {
    this.authToken = getStorageItem(STORAGE_AUTH_TOKEN_KEY);
    return !!this.authToken;
  };

  setAuthToken = token => {
    this.authToken = token;
    setStorageItem(STORAGE_AUTH_TOKEN_KEY, token);
  };

  getAuthToken = () => {
    this.authToken = getStorageItem(STORAGE_AUTH_TOKEN_KEY);
    return this.authToken;
  };

  removeAuthToken = () => {
    this.authToken = null;
    removeStorageItem(STORAGE_AUTH_TOKEN_KEY);
  };

  logout = () => {
    this.removeAuthToken();
  };

  addTokenToConfig = (config = {}) => {
    const token = this.getAuthToken();
    if(token) {
      config.headers = config.headers || {};
      config.headers.Authorization = 'Token ' + token;
    }
    return config;
  };

  post = async(url, params = {}, config = {}) => {
    const configToken = await this.addTokenToConfig(config);
    return axios.post(url, params, configToken);
  };

  put = async(url, params = {}, config = {}) => {
    const configToken = await this.addTokenToConfig(config);
    return axios.put(url, params, configToken);
  };

  patch = async(url, params = {}, config = {}) => {
    const configToken = await this.addTokenToConfig(config);
    return axios.patch(url, params, configToken);
  };

  get = async(url, params = {}, config = {}) => {
    try {
      const configToken = await this.addTokenToConfig(config);
      configToken.params = params;
      return axios.get(url, configToken);
    } catch({ response }) {
      if(response.request.status === 401) {
        await this.logout();
      }
    }
  };

  delete = async(url, config = {}) => {
    const configToken = await this.addTokenToConfig(config);
    return axios.delete(url, configToken);
  };

  fake(url, data = {}, error = null) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if(error === null) {
          resolve({ data });
        } else {
          reject({
            error,
          });
        }
      }, 500);
    });
  }

  getQueryParams(key = null) {
    const urlParams = new URLSearchParams(document.location.search);
    return urlParams.get(key);
  }

  getRequestErrorMessage(error = {}, defaultMessage = '', additionalMessages = {}) {
    let message = defaultMessage;
    const { data: { errors } = {}, status } = error.response;

    if(error.response) {
      const isErrorString = typeof errors === 'string';

      const strategies = new Enum('string', 'array', 'object');
      const strategy = Array.isArray(errors)
        ? strategies.array
        : isPlainObject(errors)
          ? strategies.object
          : isErrorString
            ? strategies.string
            : null;

      switch(strategy) {
        case strategies.string:
          message = errors;
          break;

        case strategies.array:
          message = errors[0];
          break;

        case strategies.object:
          const errorArray = Object.entries(errors);
          const [[errorKey, [errorText]]] = errorArray;
          message = `${capitalize(errorKey)} ${errorText}`;
          break;

        default:
          break;
      }

      if(typeof additionalMessages[status] !== 'undefined') {
        message = additionalMessages[status];
      }
    } else if(typeof error.message === 'string') {
      message = error.message;
    }

    return additionalMessages[status] || message;
  }
}

export const api = new API();
