export const objToCamelCase = obj => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const modifiedToCamelCase = key.replace(/_([a-z])/g, g => g[1].toUpperCase());

    return {
      ...acc,
      ...{ [modifiedToCamelCase]: value },
    };
  }, {});
};
