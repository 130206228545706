import * as TYPES from '../actionTypes';

import { api, BASE_URL } from './../../utils/api';


export const getProfile = () => ({
  async: async dispatch => {
    const { data } = await api.get(`${BASE_URL}profile`);
    dispatch({ type: TYPES.GET_PROFILE, payload: data });
  },
  defaultErrorMessage: 'Cannot get profile data',
  type: TYPES.GET_PROFILE,
});

export const editProfile = user => ({
  async: async dispatch => {
    const userData = {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
    };

    const { data } = await api.patch(`${BASE_URL}profile`, userData);
    dispatch({ type: TYPES.GET_PROFILE, payload: data });
  },
  defaultErrorMessage: 'Cannot edit profile',
  type: TYPES.GET_PROFILE,
});
