import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { withRouter } from 'react-router-dom';
import { Button, Layout, PasswordField } from 'components';
import { loginUser } from 'actions/auth';
import { showModal } from 'actions/modal';
import * as TYPES from 'store/actionTypes';

import './Login.scss';

const Login = ({ isLoggedIn, history, loginUser, showModal }) => {
  if(isLoggedIn) {
    history.push('/videos');
  }

  return (
    <Layout waitFor={[TYPES.LOGIN_USER]}>
      <div className="small-container">
        <div className="login">
          <h2>Login</h2>
          <Form
            onSubmit={loginUser}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting }) => (
              <form
                noValidate
                className="login-form"
                onSubmit={handleSubmit}
              >
                <Field
                  fullWidth
                  component={TextField}
                  label="E-post adresse"
                  name="email"
                  type="email"
                />
                <Field
                  component={PasswordField}
                  label="Password"
                  name="password"
                  type="password"
                />
                <p
                  className="forgotten-password"
                  onClick={() => showModal({ name: 'requestResetLink' })}
                >
                  Jeg har glemt passordet mitt
                </p>
                <div className="btns-container">
                  <Button
                    disabled={submitting}
                    name="Fortsett"
                    size="large"
                    type="submit"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = ({ isLoggedIn }) => {
  return {
    isLoggedIn,
  };
};

const mapDispatchToProps = {
  loginUser,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
