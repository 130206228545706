export const isRequired = value => (value ? undefined : 'Påkrevd');

export const isEmailValid = email => {
  const regexEmailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexEmailValidation.test(email) ? undefined : 'Bruk riktig e-postadresse';
};

export const isPasswordValid = password => {
  return password && password.length >= 8 ? undefined : 'Passordet må inneholde minst 8 tegn';
};

export const isCheckboxChecked = value => (value ? undefined : 'Du må godta vilkårene og betingelsene');
