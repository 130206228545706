import React from 'react';
import { Field, Form } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Button } from 'components';
import { isEmailValid, isRequired } from 'utils/validationMethods';

import './EditModeUserBasicInfo.scss';

const EditModeUserBasicInfo = ({ handleCancelEditMode, saveEditedUserData, profile }) => {
  return (
    <Form
      initialValues={profile}
      onSubmit={saveEditedUserData}
      render={({ handleSubmit, submitting }) => (
        <form
          noValidate
          className="basicInfoEditMode"
          onSubmit={handleSubmit}
        >
          <div className="field">
            <Field
              fullWidth
              component={TextField}
              label="Fornavn"
              name="firstName"
              type="text"
              validate={isRequired}
            />
          </div>
          <div className="field">
            <Field
              fullWidth
              component={TextField}
              label="Etternavn"
              name="lastName"
              type="text"
              validate={isRequired}
            />
          </div>
          <div className="field">
            <Field
              fullWidth
              component={TextField}
              label="E-post adresse"
              name="email"
              type="email"
              validate={isEmailValid}
            />
          </div>
          <div className="btns-container">
            <p onClick={handleCancelEditMode}>Avbryt</p>
            <Button
              disabled={submitting}
              name="Lagre"
              size="medium"
              type="submit"
            />
          </div>
        </form>
      )}
    />
  );
};

export default EditModeUserBasicInfo;
