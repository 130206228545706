import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { VIMEO_URL } from 'consts/urls';
import { getStorageItem, hasStorageKey, setStorageItem } from 'utils/localStorage';

const FINISHED_VIDEO_TRESHOLD = 3; // seconds
const STORAGE_VIDEOS_DATA_KEY = 'VIDEOS_DATA';

import './VideoItem.scss';


const VideoItem = ({ onVideoClose, setVideoCompletionStatus, vimeoId, videoQuiz, onShowQuiz }) => {
  const [ playingStatus, setPlayingStatus ] = useState(false);
  const player = useRef();
  const hasQuiz = !!videoQuiz;

  const saveVideoProgressToLocalStorage = () => {
    const currentVideoTime = player.current.getCurrentTime();

    const videoPlayedDuration = {
      [vimeoId]: currentVideoTime,
    };

    player.current.getCurrentTime() &&
      setStorageItem(STORAGE_VIDEOS_DATA_KEY, JSON.stringify(videoPlayedDuration));
  };

  const setVideoEndStatus = () => {
    const currentVideoTime = player.current.getCurrentTime();
    const currentVideoDuration = player.current.getDuration();
    const isVideoFinished = currentVideoTime > (currentVideoDuration - FINISHED_VIDEO_TRESHOLD);

    setVideoCompletionStatus(isVideoFinished);
  };

  useEffect(() => {
    window.addEventListener(
      'beforeunload',
      saveVideoProgressToLocalStorage
    );

    return () => {
      window.removeEventListener(
        'beforeunload',
        saveVideoProgressToLocalStorage
      );

      saveVideoProgressToLocalStorage();
      setVideoEndStatus();
    };
  }, []);

  const handlePlayingStatus = useCallback(() => {
    seekToPoint();

    setPlayingStatus(true);
  }, []);

  const clearVideoSavedTime = () => {
    const videoStartPoint = {
      [vimeoId]: 0,
    };

    setStorageItem(STORAGE_VIDEOS_DATA_KEY, JSON.stringify(videoStartPoint));
  };

  const seekToPoint = () => {
    const isVideoSavedInLocalStorage = hasStorageKey(STORAGE_VIDEOS_DATA_KEY);

    if(isVideoSavedInLocalStorage) {
      const videoData = JSON.parse(getStorageItem(STORAGE_VIDEOS_DATA_KEY));
      const toReturnVideoPoint = Math.floor(Number(videoData[vimeoId])) || 0;
      player.current.seekTo(toReturnVideoPoint);
    }
  };

  const handleVideoEnd = () => {
    setVideoCompletionStatus();
    if (hasQuiz) {
      onShowQuiz();
      onVideoClose();
      clearVideoSavedTime();
    }
  };

  return createPortal(
    <div className="video-modal-background" onClick={onVideoClose}>
      <div className="video-modal-window">
        <ReactPlayer
          playsinline
          className="react-player"
          onEnded={handleVideoEnd}
          onReady={handlePlayingStatus}
          playing={playingStatus}
          ref={player}
          url={VIMEO_URL + vimeoId}
        />
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

const mapStateToProps = ({ modalData: { data } }) => ({
  modalData: data,
});

export default connect(mapStateToProps, null)(VideoItem);
