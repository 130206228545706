import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components';
import { hideModal } from 'actions/modal';


const ConnectedModal = ({ hideModal, name, onAgree = hideModal, openedModal, ...props }) => {
  const isModalNameMatches = name === openedModal;

  return isModalNameMatches ? (
    <Modal
      isOpened={isModalNameMatches}
      onAgree={onAgree}
      onCancel={hideModal}
      onClose={hideModal}
      {...props}
    />
  ) : null;
};


const mapStateToProps = ({ modalData }) => {
  return {
    openedModal: modalData.name,
  };
};

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedModal);
