import * as TYPES from '../actionTypes';
import { objToCamelCase } from '../../utils/formatters';

const INITIAL_STATE = {
  videos: [],
  isCurrentVideoCompleted: false,
  currentVideoId: null,
  currentVideoTitle: null,
  videoVisibility: null,
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.GET_VIDEOS:
      const videos = action.payload.map(item => objToCamelCase(item));
      return {
        ...state,
        videos,
      };

    case TYPES.CURRENT_VIDEO_COMPLETION_STATUS:
      return {
        ...state,
        isCurrentVideoCompleted: action.payload,
      };

    case TYPES.SET_CURRENT_VIDEO_ID:
      return {
        ...state,
        currentVideoId: action.payload,
      };

    case TYPES.SET_CURRENT_VIDEO_TITLE:
      return {
        ...state,
        currentVideoTitle: action.payload,
      };

    case TYPES.SET_VIDEO_VISIBILITY:
      return {
        ...state,
        videoVisibility: action.payload,
      };

    default:
      break;
  }

  return state;
};
