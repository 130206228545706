import React, { cloneElement, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Close } from '@material-ui/icons';
import { Loader } from 'components';

import Button from '../Button';

import './Modal.scss';


const Modal = ({
  additionalAction,
  additionalText,
  className = 'modal-window',
  btnText,
  children,
  isConfirmationModal = false,
  isOpened,
  mainHeader,
  onClose,
  onAgree,
  onCancel,
  subHeader,
  waitFor = [],
}) => {
  return createPortal(
    <Fragment>
      { isOpened && (
        <div className="modal-background">
          <section className={className}>
            <Loader waitFor={waitFor} />
            {mainHeader && <h3>{mainHeader}</h3>}
            {subHeader && <p>{subHeader}</p>}
            {additionalText && <p>{additionalText}</p>}

            { !isConfirmationModal && cloneElement(children, { additionalAction, onAgree, onCancel }) }

            { isConfirmationModal &&
              <div className="btns-container">
                <Button
                  handleClick={onAgree}
                  name={btnText}
                  size="large"
                />
              </div>
            }
            <Close className="close-modal-btn" onClick={onClose} />
          </section>
        </div>
      )}
    </Fragment>,
    document.getElementById('modal-root')
  );
};


export default Modal;
