import * as TYPES from '../actionTypes';

import { api, BASE_URL } from './../../utils/api';


export const getCerificate = () => ({
  async: async() => {
    const { data } = await api.get(`${BASE_URL}certificate.pdf`, {}, { responseType: 'blob' });

    const pdfFile = new Blob(
      [data],
      { type: 'application/pdf' }
    );

    return pdfFile;
  },
  defaultErrorMessage: 'Cannot get certificate',
  type: TYPES.GET_CERTIFICATE,
});

export const getAndOpenCertificate = () => ({
  async: async dispatch => {
    const certificateFile = await dispatch(getCerificate());

    const fileURL = URL.createObjectURL(certificateFile);
    window.open(fileURL);
  },
});
