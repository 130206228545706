import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'components';

import './PageNotFound.scss';

const PageNotFound = ({ history }) => {
  return (
    <div className="not-found-container">
      <h3>Sorry...</h3>
      <p>we didn’t find the site <br />you were looking for.</p>
      <Button
        name="go back"
        size="large"
        handleClick={history.goBack}
      />
    </div>
  );
};

export default withRouter(PageNotFound);
