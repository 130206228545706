import * as TYPES from '../actionTypes';
import { api, BASE_URL } from '../../utils/api';
import history from '../../history';

import { showModal } from './modal';

export const activateUser = userData => ({
  async: async dispatch => {
    dispatch(logoutUser(false));

    const user = {
      email: userData.email,
      first_name: userData.firstName,
      last_name: userData.lastName,
      password: userData.password,
      token: api.getQueryParams('token'),
    };

    const { data } = await api.post(`${BASE_URL}users`, user);

    const userLoginData = {
      email: data.email,
      password: userData.password,
    };

    dispatch(loginUser(userLoginData));
  },
  defaultErrorMessage: 'Cannot activate user',
  type: TYPES.ACTIVATE_USER,
});

export const loginUser = ({ email, password }) => ({
  async: async dispatch => {
    const { data } = await api.post(`${BASE_URL}session`, { email, password });
    const { token } = data;

    api.setAuthToken(token);

    dispatch({ type: TYPES.LOGIN_USER });
    history.push('/videos');
  },
  defaultErrorMessage: 'Cannot log in!',
  skipDefaultErrorActions: true,
  type: TYPES.LOGIN_USER,
});

export const logoutUser = (shouldRedirect = true) => ({
  async: async dispatch => {
    api.logout();
    shouldRedirect && history.push('/');

    dispatch({ type: TYPES.LOGOUT_USER });
  },
  defaultErrorMessage: 'Cannot log out!',
  skipDefaultErrorActions: true,
  type: TYPES.LOGOUT_USER,
});

export const setUserLoginStatus = () => dispatch => {
  const isUserLoggedIn = api.isLoggedIn();

  if(isUserLoggedIn) dispatch({ type: TYPES.LOGIN_USER });
};

export const requestResetLink = email => ({
  async: async() => {
    const resetPasswordData = {
      email,
      redirect_url: document.location.origin + '/reset-password',
    };

    await api.post(`${BASE_URL}password_reset`, resetPasswordData);
  },
  defaultErrorMessage: 'Password could not be reset',
  type: TYPES.REQUEST_RESET,
});

export const resetPassword = (newPassword, nextModalToShow) => ({
  async: async dispatch => {
    const token = api.getQueryParams('token');
    const resetPasswordData = {
      new_password: newPassword,
      token,
    };

    await api.patch(`${BASE_URL}password_reset`, resetPasswordData);
    dispatch(showModal({ name: nextModalToShow }));
  },
  defaultErrorMessage: 'Password could not be reset',
  type: TYPES.RESET_PASSWORD,
});
